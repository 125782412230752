

export default function OpsDashboardPage( { } ){
    return(
        <>
            <iframe width={"100%"}
                    height={'800px'} 
                    src={"https://lookerstudio.google.com/embed/reporting/0e8762a9-719f-4cc2-af05-5945cd34cd85/"}
                    frameBorder="0" 
                    >
            </iframe>
        </>
    )
}