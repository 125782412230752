import {
  Flex,
  GridItem,
  SimpleGrid,
  Box,
  Text,
  Center,
  Image,
  Avatar,
  IconButton,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  doc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { FormControl } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Select } from "chakra-react-select";
import { currencyFormatter } from "../../functions/formatAsCurrency";
import { FiArrowRight, FiSettings } from "react-icons/fi";
import { Skeleton, Grid, AvatarGroup } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
import StatisticCard from "../../components/StatisticCard";
import HelpfulLinkSections from "../../components/HelpfulLinks/HelpfulLinkSections";
import GroupChat from "../../components/Client/GroupChat";
import FundedUnitsGoal from "../../components/FundedUnitsGoal";
import RateScenariosModal from "../../components/RateScenariosModal";
import CalendarList from "./components/CalendarList";
import { useCalendars } from "../CalendarSettings/Calendars/useCalendars";
import useActivities from "../CalendarSettings/Activities/useActivities";
import RateScenariosTimestamp from "./components/RateScenariosTimestamp";
import LOFeedback_Multiple from "../../components/LOFeedback/LOFeedback_Multiple";
import { useBudget } from "../Profile/useBudget";
import { ExternalLinkIcon, LinkIcon } from "@chakra-ui/icons";

// import * as calendar from './calendar.json'
export const lineChartOptions = {
  chart: {
    title: "test",
    header: "Test",
    toolbar: {
      show: true,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "string",
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    type: "integer",
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
  },
  legend: {
    show: true,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#4FD1C5", "#2D3748"],
  },
  colors: ["#4FD1C5", "#2D3748"],
};
function getFormattedDate() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const today = new Date();
  const month = months[today.getMonth()];
  const day = today.getDate();
  const year = today.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
}
export default function MyDashboard() {
  const boxShadow = "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;";
  const { SuperAdmin, currentUser } = useAuth();
  const { users, brokers } = useAuth();
  const history = useHistory();

  const { calendars } = useCalendars();
  const { activities } = useActivities();

  const [calendar, setCalendar] = useState();

  const [stackRankLOs, setStackRankLOs] = useState();
  const [loadingCalendar, setLoadingCalendar] = useState(true);
  const [selectedUser, setSelectedUser] = useState();
  const [error, setError] = useState(false);
  const [fundedDataByMonth, setFundedDataByMonth] = useState();
  const [stats, setStats] = useState();
  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const [goal, setGoal] = useState();
  let unsubGoalRef = useRef();

  const { transactions, currentSpend } = useBudget(selectedUser, y);

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthNumber = new Date().getMonth();

  const findCurrentUserIndex = () => {
    return users.findIndex((x) => x.value === currentUser.uid);
  };

  const handleGetGoal = async () => {
    unsubGoalRef.current && unsubGoalRef.current();
    setGoal(null);
    unsubGoalRef.current = onSnapshot(
      doc(db, "users", selectedUser.value),
      (doc) => {
        if (doc.data()) {
          setGoal(
            doc.data()["Funded Units Goal"]
              ? doc.data()["Funded Units Goal"]
              : ""
          );
        } else setGoal(null);
      }
    );
    return;
  };

  function makeStats() {
    if (stackRankLOs && stackRankLOs.summaryList && selectedUser) {
      let userIndex = stackRankLOs.summaryList.findIndex(
        (val) => val?.LO?.uid === selectedUser.value
      );
      if (userIndex > -1) {
        let userData = stackRankLOs.summaryList[userIndex];
        return [
          {
            label: "Loans In Pipeline",
            number: userData.noActive ? Math.round(userData.noActive) : 0,
            average: stackRankLOs.averageActiveLoans
              ? Math.round(stackRankLOs.averageActiveLoans)
              : 0,
          },
          {
            label: "Pipeline Volume",
            number: userData.dollarVolumeActive
              ? currencyFormatter(userData.dollarVolumeActive, "0")
              : 0,
            average: stackRankLOs.averageActiveVolume
              ? currencyFormatter(stackRankLOs.averageActiveVolume, "0")
              : 0,
          },
          {
            label: "Loans Closed",
            number: userData.noFundedYTD ? userData.noFundedYTD : 0,
            average: stackRankLOs.averageLoansFunded
              ? Math.round(stackRankLOs.averageLoansFunded)
              : 0,
          },
          {
            label: "Pull Through",
            number: userData.pullThrough
              ? Math.round(userData.pullThrough).toString() + "%"
              : 0,
            average: stackRankLOs.averagePullThrough
              ? Math.round(stackRankLOs.averagePullThrough).toString() + "%"
              : 0,
          },
          {
            label: "Closed Volume",
            number: userData.dollarVolumeYTD
              ? currencyFormatter(userData.dollarVolumeYTD, "0")
              : 0,
            average: stackRankLOs.averageClosedVolume
              ? currencyFormatter(stackRankLOs.averageClosedVolume, "0")
              : 0,
          },
          {
            label: "Marketing Spend",
            number: `$${currentSpend.toString()}`,
            link: {
              route: "/profile",
              label: "View",
            },
          },
        ];
      } else
        return [
          {
            label: "Loans In Pipeline",
            number: 0,
            average: stackRankLOs.averageActiveLoans
              ? Math.round(stackRankLOs.averageActiveLoans)
              : 0,
          },
          {
            label: "Pipeline Volume",
            number: 0,
            average: stackRankLOs.averageActiveVolume
              ? currencyFormatter(stackRankLOs.averageActiveVolume, "0")
              : 0,
          },
          {
            label: "Loans Closed",
            number: 0,
            average: stackRankLOs.averageLoansFunded
              ? Math.round(stackRankLOs.averageLoansFunded)
              : 0,
          },
          {
            label: "Pull Through",
            number: 0,
            average: stackRankLOs.averagePullThrough
              ? Math.round(stackRankLOs.averagePullThrough).toString() + "%"
              : 0,
          },
          {
            label: "Closed Volume",
            number: 0,
            average: stackRankLOs.averageClosedVolume
              ? currencyFormatter(stackRankLOs.averageClosedVolume, "0")
              : 0,
          },
          {
            label: "Marketing Spend",
            number: `$${currentSpend.toString()}`,
            link: {
              route: "/profile",
              label: "View",
            },
          },
        ];
    } else return null;
  }

  useEffect(async () => {
    async function getStackRankLOs() {
      const q = query(
        collection(db, "Stack Rank LOs Lifetime"),
        orderBy("timestamp", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setStackRankLOs(doc.data());
      });
    }
    await getStackRankLOs();
  }, []);

  useEffect(() => {
    if (users) {
      let currentUserIndex = findCurrentUserIndex();
      currentUserIndex > -1 && setSelectedUser(users[currentUserIndex]);
    }
  }, [users]);

  useEffect(async () => {
    selectedUser && (await handleGetGoal());
    return () => {
      unsubGoalRef.current && unsubGoalRef.current();
    };
  }, [selectedUser]);

  useEffect(() => {
    if (stackRankLOs && selectedUser && (currentSpend || currentSpend === 0)) {
      setStats(makeStats());
      setFundedDataByMonth(null);
      setFundedDataByMonth(makeFundedDataByMonth());
    }
  }, [stackRankLOs, selectedUser, currentSpend]);

  useEffect(() => {
    setFundedDataByMonth(null);
    setFundedDataByMonth(makeFundedDataByMonth());
  }, [goal]);

  // Get the calendar for the current user based on their funded units goal and the range setting in calendars
  useEffect(() => {
    function findCalendar() {
      let g = goal ? goal : 5;
      calendars?.forEach((cal) => {
        if (
          cal.lower &&
          cal.upper &&
          parseInt(cal.lower) <= parseInt(g) &&
          parseInt(cal.upper) >= parseInt(g)
        ) {
          return cal;
        }
      });
      return calendars && calendars[0] && calendars[0];
    }

    calendars && setCalendar(findCalendar());
  }, [goal, calendars]);

  useEffect(() => {
    calendar && setLoadingCalendar(false);
  }, [calendar]);

  /* Explanation of makeFundedDataByMonth:

        A running total of the LOs funded loans by month is needed for the line chart on this page
        This is calculated server side for each LO in the function stackRankLOs_Lifetime
        example:
        {
            name: 'Actual (55)',
            data: [5, 12, 19, 23, 30, 39, 41, 49, 55]
        }

        this function will get the selected users data (which is already in the format above)
        it will also create data in this same format for their goal
        so it returns something like this:

        [
            {
                name: 'Actual (55)',
                data: [5, 12, 19, 23, 30, 39, 41, 49, 55]
            },
            {
                name: 'Goal (120)',
                data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120]
            }
        ]
    */
  function makeFundedDataByMonth() {
    let runningTotalGoal = 0;
    let arrToReturn = [];

    if (stackRankLOs && stackRankLOs.summaryList && selectedUser) {
      let userIndex = stackRankLOs.summaryList.findIndex(
        (val) => val?.LO?.uid === selectedUser.value
      );
      if (
        userIndex > -1 &&
        stackRankLOs?.summaryList?.[userIndex]?.fundedDataByMonth
      ) {
        arrToReturn.push(
          stackRankLOs?.summaryList?.[userIndex]?.fundedDataByMonth
        );
      }
      // if the LO has no funded loans or no data, populate data with zeros
      else {
        let actualData = [];
        for (let i = 0; i <= currentMonthNumber; i++) {
          actualData.push(0);
        }
        arrToReturn.push({
          name: `Actual (0)`,
          data: actualData,
        });
      }
    }
    if (goal) {
      let goalData = labels.map(() => {
        let count1 = parseInt(goal) / labels.length;
        runningTotalGoal += count1;
        return parseInt(runningTotalGoal);
      });

      arrToReturn.push({
        name: `Goal (${runningTotalGoal.toFixed(0).toString()})`,
        data: goalData,
      });
    } else {
      arrToReturn.push({
        name: `Goal (${runningTotalGoal.toFixed(0).toString()})`,
        data: [],
      });
    }
    return arrToReturn.length > 0 ? arrToReturn : null;
  }

  return (
    <>
      <Flex flexDirection="column">
        <Flex
          maxW={{ base: "90vw", md: "88vw" }}
          flexDir="row"
          justify="space-between"
          flexWrap={"wrap"}
        >
          <Box order={1}></Box>
          <Flex flexDir={"row"} order={{ base: 2, md: 1 }}>
            {SuperAdmin && users && (
              <FormControl w="250px">
                <Select
                  options={users}
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e)}
                  placeholder="Select User"
                  closeMenuOnSelect={true}
                  selectedOptionColor="green"
                  classNamePrefix={"user-select"}
                />
              </FormControl>
            )}
          </Flex>
          <Flex order={1}>
            <LOFeedback_Multiple />
            <Flex flexDir={"column"} align="flex-end" ml="2">
              <Button
                onClick={() => history.push("/my-dashboard/recent-submissions")}
                rightIcon={<ExternalLinkIcon />}
                variant="ghost"
                size="xs"
                fontSize="sm"
              >
                Recent Submissions
              </Button>
              <Button
                onClick={() => history.push("/my-dashboard/recent-feedback")}
                rightIcon={<ExternalLinkIcon />}
                variant="ghost"
                size="xs"
                fontSize="sm"
              >
                Recent Feedback
              </Button>
            </Flex>
            <Flex flexDir={"column"} align="flex-end" ml="2">
              <Button
                onClick={() => history.push("/my-dashboard/lender-matrix")}
                rightIcon={<ExternalLinkIcon />}
                variant="ghost"
                size="xs"
                fontSize="sm"
              >
                Lender Matrix
              </Button>
              <Button
                onClick={() => history.push("/marketing-matrix")}
                rightIcon={<ExternalLinkIcon />}
                variant="ghost"
                size="xs"
                fontSize="sm"
              >
                Marketing Matrix
              </Button>
            </Flex>
            {selectedUser && (
              <>
                {/* <IconButton size='lg' icon={<GrRefresh/>} ml='2' variant={'ghost'} disabled={!selectedUser || loadingCalendar} onClick={() => setSelectedUser({...selectedUser})}/>  */}

                {selectedUser.photoURL && (
                  <>
                    <AvatarGroup>
                      {/* <Avatar size='md' src='https://api.dicebear.com/6.x/adventurer/svg?seed=Charlie' />
                            <Avatar size='md' src='https://api.dicebear.com/6.x/adventurer/svg?seed=Harley' /> */}
                      <Avatar size="md" src={selectedUser.photoURL} />
                    </AvatarGroup>
                  </>
                )}
              </>
            )}
          </Flex>
        </Flex>
        {stats && stats.length > 0 ? (
          <SimpleGrid
            columns={{ sm: 2, xl: stats.length }}
            spacing={{ sm: "12px", xl: "24px" }}
          >
            {stats.map((stat) => (
              <StatisticCard
                key={stat.label}
                number={stat?.number}
                label={stat?.label}
                average={stat?.average}
                link={stat?.link}
              />
            ))}
          </SimpleGrid>
        ) : (
          <SimpleGrid
            columns={{ sm: 2, xl: 5 }}
            spacing={{ sm: "12px", xl: "24px" }}
          >
            <Skeleton borderRadius="md" height="83px"></Skeleton>
            <Skeleton borderRadius="md" height="83px"></Skeleton>
            <Skeleton borderRadius="md" height="83px"></Skeleton>
            <Skeleton borderRadius="md" height="83px"></Skeleton>
            <Skeleton borderRadius="md" height="83px"></Skeleton>
          </SimpleGrid>
        )}
        <>
          <Grid
            h={{ base: "", xl: "72vh" }}
            mt="5"
            maxW={{ base: "95vw", md: "none" }}
            templateRows={{ base: "none", xl: "repeat(2, 1fr)" }}
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(4, 1fr)",
              xl: "repeat(9, 1fr)",
            }}
            gap={4}
          >
            <GridItem
              rowSpan={2}
              colSpan={{ base: 1, md: 2 }}
              boxShadow="md"
              borderRadius="lg"
              overflowY={"auto"}
              maxW={{ base: "92vw", md: "none" }}
              maxH={{ base: "42vh", xl: "none" }}
              p="3"
            >
              <RateScenariosModal />
              <RateScenariosTimestamp />
              <HelpfulLinkSections noBoxShadow />
            </GridItem>
            <GridItem
              colSpan={{ base: 1, md: 2, xl: 4 }}
              height={"43vh"}
              maxW={{ base: "92vw", md: "none" }}
              boxShadow="md"
              borderRadius="lg"
              p="3"
            >
              <GroupChat
                collectionRef={collection(db, "Dashboard Announcements")}
                restrictPosting
                heading="Announcements"
                collectionName="Dashboard Announcements"
                parentID="n/a"
                isDashboard
                width="100%"
                borderWidth={"none"}
                maxMsgs={30}
              />
            </GridItem>
            <GridItem
              colSpan={{ base: 1, md: 4, xl: 3 }}
              boxShadow="md"
              height={"43vh"}
              maxW={{ base: "92vw", md: "none" }}
              borderRadius="lg"
              p="3"
            >
              <Flex align="flex-start">
                {goal || goal == "" ? (
                  <FundedUnitsGoal
                    goal={goal}
                    setGoal={setGoal}
                    selectedUser={selectedUser}
                  />
                ) : (
                  <Spinner size="md" mt="1" mb="1" />
                )}
              </Flex>
              {fundedDataByMonth ? (
                <ReactApexChart
                  options={lineChartOptions}
                  series={fundedDataByMonth}
                  type="area"
                  width="100%"
                  height="95%"
                />
              ) : (
                <Skeleton
                  boxShadow="md"
                  borderRadius="lg"
                  p="3"
                  minHeight="37vh"
                ></Skeleton>
              )}
            </GridItem>
            <GridItem
              colSpan={{ base: 1, md: 4, xl: 7 }}
              maxW={{ base: "92vw", md: "none" }}
              boxShadow="md"
              borderRadius="lg"
              p="3"
            >
              {SuperAdmin && (
                <IconButton
                  position="absolute"
                  variant="ghost"
                  icon={<FiSettings />}
                  onClick={() =>
                    history.push("/my-dashboard/calendar-settings")
                  }
                ></IconButton>
              )}
              {!loadingCalendar && (
                <CalendarList calendar={calendar} allActivities={activities} />
              )}
            </GridItem>
          </Grid>
        </>
      </Flex>

      {error && (
        <Center flexDir={"column"} mt="150px">
          <Image src={require("../../assets/img/cat-error.png")} />
          <Text colorScheme={"gray"} fontSize={"sm"}>
            Error reading pipeline. Please try again.
          </Text>
        </Center>
      )}
    </>
  );
}
